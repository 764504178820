<template>
  <div class="page-content mt-0">
    <div class="full_container">
        <div class="left_login">
          <div class="bg_left_login" :style="`background-image: url('/img/cover_login.png');`">
          </div>
        </div>
        <!-- Login form -->
        
        <validation-observer
          v-slot="{ handleSubmit }" ref="VForm" class="right_login_wrap"
        >
        <b-form
          class="login-form"
          @submit.prevent="handleSubmit(doLogin('/do/login'))"
          novalidate
        >
          <div class=" mb-0">
            <div>
              <div class="text-center mb-3">
                <img class="mb-2" :src="assetLocal('global_assets/images/logo-rsh-wide.png')" width="200px" />
                <h5 class="mb-0">Login to your account</h5>
                <span class="d-block text-muted">Your credentials</span>
              </div>
              <b-form-group class="form-group-feedback form-group-feedback-left">
                <b-form-input v-model="input.username" placeholder="Username" />
                <div class="form-control-feedback">
                  <i class="icon-user text-muted"></i>
                </div>
                <VValidate 
                  name="Username" 
                  v-model="input.username" 
                  rules="required"
                />
              </b-form-group>
              <b-form-group class="form-group-feedback form-group-feedback-left">
                  <b-form-input :type="(pwdOpen?'text':'password')" v-model="input.password" placeholder="Password" />
                  
                  <div class="form-control-feedback" @click="pwdOpen=!pwdOpen">
                    <i :class="{'icon-lock2':!pwdOpen, 'icon-unlocked': pwdOpen}"></i>
                  </div>
                  
                  <VValidate 
                    name="Password" 
                    v-model="input.password" 
                    rules="required"
                  />
              </b-form-group>
              <b-form-group class="d-flex align-items-center">
                <b-form-checkbox v-model="input.rememberMe">Remember Me</b-form-checkbox>
              </b-form-group>
              <div class="infoHTML" id="info-login"></div>
              <div class="form-group">
                <b-button type="submit" variant="primary" class="btn btn-primary bg-primary btn-block" block>Login <i class="icon-circle-right2 ml-2"></i></b-button>
              </div>
              <span class="form-text text-center text-muted">© 2023 RS Harapan Magelang. All Rights Reserved.</span>
            </div>
          </div>
        </b-form>
        </validation-observer>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global'
import $ from 'jquery'

export default {
  extends:GlobalVue,
  data() {
    return {
      type:'login',
      pwdOpen: false,
      loading: false
    }
  },
  methods: {
    doLogin(action){
      this.$refs['VForm'].validate().then(success => {
        if (!success) return
        var infoLogin = $("#info-login")
        infoLogin.attr("class","infoHTML alert alert-info")
        $("#info-login").html("Authenticating and authorizing...")
        this.loading = true
        setTimeout(()=>{
          this.input.isLogin = true
          this.doSubmit(action, this.input, (_, resp)=>{
            this.loading = false
            if (resp.status != 'success') return false

            if (!resp.data.token) return this.type = "2fa"
            this.$store.commit('updateUserToken', { token: resp.data.token, isRememberMe: resp.data.isRememberMe })
            this.$store.commit('updateUser', { user: resp.data.user })
            if(this.$route.query.continue) {
              return window.location=this.baseUrl+''+this.$route.query.continue
            }
            window.location=this.baseUrl
          })
        },500)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
  .login-register {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/backoffice/assets/images/login-bg.jpg");
  }
  .login-box {
    width: 400px;
    margin: 0 auto;
  }
</style>